import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PocketBase from 'pocketbase';

import { CardActionArea, Card, CardContent, Typography, Grid, Button, Link, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const pb = new PocketBase(process.env.REACT_APP_API_DOMAIN);

function formatPhoneNumber(phoneNumber) {
    // Convert the number to a string for easier manipulation
    const phoneStr = phoneNumber.toString();

    // Extract area code, first 3 digits, and last 4 digits
    const areaCode = phoneStr.slice(0, 3);
    const first3 = phoneStr.slice(3, 6);
    const last4 = phoneStr.slice(6, 10);

    // Format and return the phone number
    return `(${areaCode}) ${first3}-${last4}`;
}

export const ChildrenCard = ({ response }) => {
    const navigate = useNavigate();

    const handleCardClick = (item) => {
        console.log(item);
        navigate('/children/' + item.id, { replace: true });
    }

    const handleButtonClick = (item) => {
        console.log(new Date());
    }

    if (response.length === 0) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="body1">No children found</Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Grid container spacing={2}>
            {response.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ display: 'flex', justifyContent: 'space-between', border: 2, borderColor: 'primary.main' }}>
                        <CardActionArea onClick={() => handleCardClick(item)}>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {item.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Medical Condition: {item.medical_conditions || 'Not Specified'}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <Button variant="contained" color="primary" onClick={() => handleButtonClick(item)}>
                            Clock In/Out
                        </Button>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export const EducatorCard = ({ response }) => {
    const navigate = useNavigate();

    const handleCardClick = (item) => {
        navigate('/educators/' + item.id, { replace: true });
    }

    return (
        <Grid container spacing={2}>
            {response.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ display: 'flex', justifyContent: 'space-between', border: 2, borderColor: 'primary.main' }}>
                        <CardActionArea onClick={() => handleCardClick(item)}>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {item.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {item.home_address || 'None'}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export const UserCard = ({ name, role, email, phone, address }) => {
    const generateGoogleMapsLink = (address) => {
        const encodedAddress = encodeURIComponent(address);
        return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    };

    return (
        <Card sx={{ mb: 1, border: 2, borderColor: 'primary.main' }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
                    {name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', textTransform: 'capitalize' }}>
                    {role}
                </Typography>
                {email && email !== '' && (
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                        <EmailIcon sx={{ mr: 1 }} />
                        <Link href={`mailto:${email}`} sx={{ flexShrink: 0 }}>
                            <span>{email}</span>
                        </Link>
                    </Typography>
                )}
                {phone && phone !== '' && (
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                        <PhoneIcon sx={{ mr: 1 }} />
                        <Link href={`tel:${phone}`} sx={{ flexShrink: 0 }}>
                            <span>{formatPhoneNumber(phone)}</span>
                        </Link>
                    </Typography>
                )}
                {address && address !== '' && (
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                        <LocationOnIcon sx={{ mr: 1 }} />
                        <Link href={generateGoogleMapsLink(address)} target="_blank" rel="noopener noreferrer" sx={{ flexShrink: 0 }}>
                            <span>{address}</span>
                        </Link>
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default function EducatorView() {
    const { id } = useParams();
    const [user, setEducator] = useState({
        id: '',
        role: '',
        name: '',
        email: '',
        phone: '',
        address: ''
    });
    const [response, setResponse] = useState([]);

    useEffect(() => {
        async function fetchChildrenIfEducator() {
            try {
                const educator = await pb.collection('educators').getOne(id);
                setEducator({
                    id: educator.id,
                    role: "educator",
                    name: educator.name,
                    email: educator.email,
                    phone: educator.phone_number,
                    address: educator.home_address
                });
                const res = await pb.collection('children').getList(1, 25, { filter: `educator="${educator.id}"`, sort: '+name'});
                setResponse(res.items);
            } catch (error) {
                console.error('Error fetching children data:', error);
            }
        }
        fetchChildrenIfEducator();
    }, [id]);

    return (
        <Box sx={{ p: 1 }}>
            <UserCard name={user.name} role={user.role} email={user.email} phone={user.phone} address={user.address} />
            {user.role === 'educator' && (
                <ChildrenCard
                    response={response}
                />
            )}
            {user.role === 'admin' && (
                <EducatorCard
                    response={response}
                />
            )}
            {user.role === 'parent' && (
                <Box />
            )}
        </Box>
    )
}
