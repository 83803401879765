import React from 'react';
import useRecordDetail from '../hooks/useRecordDetail';
import { useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import ChildDetailCard from '../components/ChildDetailCardComp';

export default function ChildDetail() {
    const { id } = useParams();
    const { record, loading, error } = useRecordDetail("children", id);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <Box sx={{ m: 2 }}>
            <Typography variant="h4" gutterBottom>Child Detail</Typography>
            <ChildDetailCard data={record} />
        </Box>
    );
}
