import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';

import Box from '@mui/material/Box';

import { useUser } from '../hooks/UserContext';

import { UserCard, EducatorCard, ChildrenCard } from '../components/EducatorComp';

const pb = new PocketBase(process.env.REACT_APP_API_DOMAIN);

const ChildCard = ({ item }) => {
    // Define the layout for ChildCard
};

export default function MainPage() {
    const { user } = useUser();
    const [response, setResponse] = useState([]);


    useEffect(() => {
        async function fetchChildrenIfEducator() {
            try {
                let res;
                if (user.role === 'educator') {
                    res = await pb.collection('children').getList(1, 25, { filter: `educator ="${user.id}"`, sort: '+name'});
                } else if (user.role === 'admin') {
                    res = await pb.collection('educators').getList(1, 50, {sort: '+name'});
                } else if (user.role === 'consultant') {
                    res = await pb.collection('educators').getList(1, 50, {filter: `consultant ="${user.id}"`, sort: '+name'});
                }
                setResponse(res.items);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
        fetchChildrenIfEducator();
    }, [user]);


    return (
        <Box sx={{ p: 1 }}>
            <UserCard name={user.name} role={user.role} email={user.email} phone={user.phone} />
            {user.role === 'educator' && (
                <ChildrenCard
                    response={response}
                />
            )}
            {user.role === 'admin' && (
                <EducatorCard
                    response={response}
                />
            )}
            {user.role === 'consultant' && (
                <EducatorCard
                    response={response}
                />
            )}
            {user.role === 'parent' && (
                <ChildCard />
            )}
        </Box>
    );
}
