import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ isLoggedIn, userRole, requiredRole, children }) {
    if (!isLoggedIn) {
        return <Navigate to="/" />;
    }

    if (requiredRole && userRole !== requiredRole) {
        return <Navigate to="/" />;
    }

    return children;
}

export default ProtectedRoute;