import { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_API_DOMAIN);

function useRecordDetail(collectionName, recordId) {
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setError(null);

        pb.collection(collectionName).getOne(recordId)
            .then(data => {
                setRecord(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });

        // Cleanup function to reset state
        return () => {
            setRecord(null);
            setLoading(true);
            setError(null);
        };
    }, [collectionName, recordId]);

    return { record, loading, error };
}

export default useRecordDetail;
