import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

import {
    Button,
    TextField,
    Typography,
    Container,
    CssBaseline,
    Box,
    Paper,
    useTheme,
    Link
} from '@mui/material';

import { useUser } from '../hooks/UserContext';

import login_background from '../assets/login_background.jpg';
import logo from '../assets/logo.png';

const pb = new PocketBase(process.env.REACT_APP_API_DOMAIN);

export default function LoginPage({ setIsLoggedIn }) {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState('');
    const { setUser } = useUser();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            let user = await pb.collection('users').authWithPassword(email, password);
            setIsLoggedIn(true);
            setUser({
                id: user.record.id,
                role: user.record.role,
                name: user.record.name,
                email: user.record.email,
                phone: user.record.phone,
            }); 
            navigate('/');
        } catch (error) {
            setLoginError(error.message);
        }
    };

    return (
        <Box
            sx={{
                height: '100vh',
                backgroundImage: `url(${login_background})`, // Replace with your actual image path
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // Changed from 'center' to 'flex-start'
                pt: 3, // Add some padding at the top
                '@media (max-width: 600px)': { // Media query for screens smaller than 600px
                    pt: 0, // Remove padding on the top for small screens
                },
            }}
        >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper
                    elevation={12}
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 4,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // This makes the Paper background slightly transparent
                    }}
                >
                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img
                            src={logo} // Replace with your actual logo path
                            alt="IG Ignite Logo"
                            style={{ height: '100px', width: '100px', borderRadius: '50%' }} // Adjust size as needed
                        />
                        <Typography component="h1" variant="h4" sx={{
                            my: 2, fontFamily: '"Merriweather", serif', fontStyle: 'italic', fontWeight: 400, display: { xs: 'none', sm: 'block' } // This line hides the component on extra-small devices
                        }}>
                            Welcome to IG Ignite
                        </Typography>
                    </Box>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {loginError && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {loginError}
                        </Typography>
                    )}
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: theme.palette.primary.main }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Paper>
                <Box
                    component="footer"
                    sx={{
                        p: 1, // Reduced padding
                        mt: 'auto',
                        width: '100%',
                        backgroundColor: 'white', // Less prominent, transparent background
                        color: 'grey', // Less prominent text color
                        fontSize: '0.75rem', // Smaller font size
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        textAlign: 'center', // Center the text
                        opacity: 0.5, // Reduce opacity to make it less prominent
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography variant="body2" color="textSecondary" align="center">
                            {new Date().getFullYear()}
                            {' © Developed and maintained by '}
                            <Link color="inherit" href="https://www.linkedin.com/in/jimmy-xu-uc/">
                                Jimmy Xu
                            </Link>{' '}
                        </Typography>
                    </Container>
                </Box>
            </Container>
        </Box>
    );
}
