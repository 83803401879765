import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';

import ChildDetail from './features/ChildDetailPage';
import MapsPage from './features/MapsPage';
import LoginPage from './features/LoginPage';
import MainPage from './features/MainPage';
import ChildrenPage from './features/ChildrenPage';

import ProtectedRoute from './components/ProtectedRouteComp';
import Layout from './components/LayoutComp';
import EducatorView from './components/EducatorComp';

import { UserProvider } from './hooks/UserContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn !== null) {
      return storedIsLoggedIn === 'true';
    } else {
      const isLoggedIn = false;
      localStorage.setItem('isLoggedIn', isLoggedIn.toString());
      return isLoggedIn;
    }
  });

  useEffect(() => {
    // Update 'isLoggedIn' in localStorage whenever the state changes
    localStorage.setItem('isLoggedIn', isLoggedIn.toString());
  }, [isLoggedIn]);


  const theme = createTheme({
    palette: {
      primary: {
        main: '#b71c1c',
      },
      secondary: {
        main: '#ff5722',
      },
    },
  });

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={isLoggedIn ? <Layout pageTitle="Inner Garden"><MainPage /></Layout> : <LoginPage setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />} />
            <Route path="/children" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Layout pageTitle="Children"><ChildrenPage /></Layout></ProtectedRoute>} />
            <Route path="/children/:id" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Layout pageTitle="Child"><ChildDetail /></Layout></ProtectedRoute>} />
            <Route path="/educators" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Layout pageTitle="Educators"></Layout></ProtectedRoute>} />
            <Route path="/educators/:id" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Layout pageTitle="Educator"><EducatorView /></Layout></ProtectedRoute>} />
            {/* <Route path="/map" element={<ProtectedRoute isLoggedIn={isLoggedIn}><Layout pageTitle="Map"><MapsPage /></Layout></ProtectedRoute>} /> */}
          </Routes>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
