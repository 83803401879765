import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_API_DOMAIN);

export default function ChildrenPage() {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const records = await pb.collection('children').getFullList();
                // Transform the records into rows for the DataGrid
                const dataRows = records.map((record, index) => ({
                    id: index, // or record.id if it's unique
                    ...record
                }));
                setRows(dataRows);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        }
        fetchData();
    });

    const columns = [
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'attendance_type', headerName: 'Attendance Type', width: 130 },
        { field: 'alberta_childcare_number', headerName: 'Alberta Childcare Number',  width: 200 },
        { field: 'preferred_name', headerName: 'Preferred Name', width: 130 },
        {
            field: 'birthday',
            headerName: 'Birthday',
            width: 130,
            type: 'dateTime',
            valueGetter: (params) => new Date(params.row.birthday),
        },
        { field: 'sex', headerName: 'Sex', width: 90 },
        { field: 'address', headerName: 'Address', width: 150 },
        { field: 'father_name', headerName: "Father's Name", width: 130 },
        { field: 'father_phone', headerName: "Father's Phone",  width: 130 },
        { field: 'father_work_phone', headerName: "Father's Work Phone",  width: 130 },
        { field: 'father_email', headerName: "Father's Email", width: 150 },
        { field: 'mother_name', headerName: "Mother's Name", width: 130 },
        { field: 'mother_phone', headerName: "Mother's Phone",  width: 130 },
        { field: 'mother_work_phone', headerName: "Mother's Work Phone",  width: 130 },
        { field: 'mother_email', headerName: "Mother's Email", width: 150 },
        { field: 'emergency_contact_name', headerName: 'Emergency Contact Name', width: 180 },
        { field: 'emergency_contact_number', headerName: 'Emergency Contact Number',  width: 200 },
        { field: 'medical_conditions', headerName: 'Medical Conditions', width: 150 },
        { field: 'emergency_contact_relationship', headerName: 'Contact Relationship', width: 180 },
        { field: 'alternative_pickup_info', headerName: 'Alternative Pickup Info', width: 180 },
        { field: 'immunization_records', headerName: 'Immunization Records', type: 'boolean', width: 180 },
        { field: 'transportation_form', headerName: 'Transportation Form', type: 'boolean', width: 180 },
        { field: 'photo_release_form', headerName: 'Photo Release Form', type: 'boolean', width: 180 },
        { field: 'financial_institution_number', headerName: 'Financial Institution Number',  width: 200 },
        { field: 'transit_number', headerName: 'Transit Number',  width: 150 },
        { field: 'account_number', headerName: 'Account Number',  width: 150 },
        { field: 'educator', headerName: 'Educator', width: 150 },
    ];

    const handleRowClick = (params) => {
        navigate(`/children/${params.row.id}`);
    };

    return (
        <Container component="main">
            <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    onRowClick={handleRowClick}
                    sx={{
                        height: '100%', // Or use '100%' if you want the DataGrid to fill the container
                        width: '100%',
                        '& .MuiDataGrid-main': { height: '100%' }, // This ensures DataGrid's main container is responsive to the div's height
                        '& .MuiDataGrid-virtualScroller': { minHeight: '100px' } // Setting minimum height for the rows container
                    }}
                />
            </div>
        </Container>
    );
}
