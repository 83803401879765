import React, { useEffect } from 'react';
import { Paper, Card, CardContent, Typography, Grid } from '@mui/material';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_API_DOMAIN);

export default function ChildDetailCard({ data }) {
    const childName = data.name;
    const [educatorName, setEducatorName] = React.useState('Loading...');

    useEffect(() => {
        async function fetchEducatorName() {
            try {
                console.log(data.educator);
                const educator_name = (await pb.collection('educators').getOne(data.educator)).name;
                setEducatorName(educator_name);
            } catch (error) {

                console.error('Error fetching educator name:', error);
            }
        }
        fetchEducatorName();
    }, [data.educator]);

    const personalDetails = {
        'Name': data.name,
        'Preferred Name': data.preferred_name,
        'Sex': data.sex,
        'Birthday': new Date(data.birthday).toLocaleDateString(),
        'Alberta Childcare Number': data.alberta_childcare_number,
    };

    const parentalInformation = {
        'Mother Name': data.mother_name,
        'Father Name': data.father_name,
        'Mother Phone': data.mother_phone ? <a href={`tel:${data.mother_phone}`}>{data.mother_phone}</a> : '',
        'Father Phone': data.father_phone ? <a href={`tel:${data.father_phone}`}>{data.father_phone}</a> : '',
        "Mother's Work Phone": data.mother_work_phone ? <a href={`tel:${data.mother_work_phone}`}>{data.mother_work_phone}</a> : '',
        "Father's Work Phone": data.father_work_phone ? <a href={`tel:${data.father_work_phone}`}>{data.father_work_phone}</a> : '',
        "Mother's Email": data.mother_email ? <a href={`mailto:${data.mother_email}`}>{data.mother_email}</a> : '',
        "Father's Email": data.father_email ? <a href={`mailto:${data.father_email}`}>{data.father_email}</a> : '',
    };

    const emergencyContact = {
        'Emergency Contact Name': data.emergency_contact_name,
        'Contact Relationship': data.emergency_contact_relationship,
        'Contact Number': data.emergency_contact_number ? <a href={`tel:${data.emergency_contact_number}`}>{data.emergency_contact_number}</a> : '',
        'Alternative Pickup Info': data.alternative_pickup_info,
    };

    const medicalInformation = {
        'Medical Conditions': data.medical_conditions,
        'Immunization Records': data.immunization_records,
        'Transportation Form': data.transportation_form,
        'Photo Release Form': data.photo_release_form,
    };

    const financialInformation = {
        'Financial Institution Number': data.financial_institution_number,
        'Transit Number': data.transit_number,
        'Account Number': data.account_number,
        'Educator': educatorName,
    };

    const renderCardSection = (title, details) => (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="h6">{title}</Typography>
                {Object.entries(details).map(([key, value]) => (
                    <Typography key={key} variant="body1">
                        {key}: {value}
                    </Typography>
                ))}
            </CardContent>
        </Card>
    );

    return (
        <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>{childName}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {renderCardSection('Personal Details', personalDetails)}
                    {renderCardSection('Parental Information', parentalInformation)}
                    {renderCardSection('Emergency Contact', emergencyContact)}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderCardSection('Medical Information', medicalInformation)}
                    {renderCardSection('Financial Information', financialInformation)}
                </Grid>
            </Grid>
        </Paper>
    );
}