import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

const client = new PocketBase(process.env.REACT_APP_API_DOMAIN);

function useLogout() {
    const navigate = useNavigate();

    const handleLogout = () => {
        client.authStore.clear(); // Clear authentication data
        localStorage.clear(); // Update local storage to reflect logout
        navigate('/', { replace: true }); // Navigate to the home page
        window.location.reload(); // Reload the page to reset the state
    };
    return handleLogout;
}

export default useLogout;
